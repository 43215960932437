@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}


@layer components {
  .btn-primary {
    @apply rounded-full w-full bg-gray-950 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition ease-in-out delay-0  hover:bg-teal-500 duration-200 ;
  }
  .btn-secondary{
    @apply rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ;
  }
  .btn-lead-page{
    @apply box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
  }
  .btn-indigo{
    @apply flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
  }
  .badge-vendeur{
    @apply  inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-sm font-medium text-green-600 ring-1 ring-inset ring-gray-500/10;
  }
  .badge-acheteur{
    @apply inline-flex items-center rounded-md bg-indigo-200 px-2 py-1 text-sm font-medium text-indigo-600 ring-1 ring-inset ring-gray-500/10;
  }
  .badge-notaire{
    @apply inline-flex items-center rounded-md bg-purple-200 px-2 py-1 text-sm font-medium text-purple-600 ring-1 ring-inset ring-gray-500/10;
  }
  .badge-agent{
    @apply inline-flex items-center rounded-md bg-teal-200 px-2 py-1 text-sm font-medium text-teal-600 ring-1 ring-inset ring-gray-500/10;
  }
  .card-hover{
    @apply hover:scale-[1.05] hover:bg-gradient-to-r hover:from-teal-200 hover:to-teal-500 transition-all hover:duration-300 ease-in-out
  }
  .input-clotere{
    @apply p-4 block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-emerald-600 sm:text-base sm:leading-6 
  }
  .radio-button{
    @apply w-full text-center items-center justify-between  p-6 text-gray-500 font-semibold bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:bg-indigo-800 peer-checked:text-white hover:text-gray-600 hover:bg-gray-100
  }
  .title-clotere{
    @apply text-black leading-[28px] !text-[30px] lg:leading-[30px] lg:text-[45px] font-bold mt-10 sm:mt-0
  }
  .bg-gradient-main{
    @apply bg-gradient-to-r from-teal-200 to-teal-500 
  }
  .link-home{
    @apply flex flex-row items-center w-full sm:w-auto justify-start gap-2 p-3 relative flex-[0_0_auto] bg-teal-100 rounded-2xl overflow-hidden hover:card-hover 
  }
  .input-upload{
    @apply block w-full text-sm text-slate-500
    file:mr-4 file:py-2 file:px-4
    file:rounded-full file:border-0
    file:text-sm file:font-semibold
    file:bg-indigo-50 file:text-indigo-700
    hover:file:bg-indigo-100
  }
  .safari-img-home{
    -webkit-height: 20px;
  }

}



/** react data table **/
.rdt_TableCol{
  background-color:#F5F7FA!important;
  font-size:14px;
  text-transform:uppercase;
}
.rdt_TableCol,.rdt_TableCell{
  padding-left: 30px!important;
  padding-right: 30px!important;
}

/** ql editor **/
.ql-container{
  border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.ql-toolbar{
  border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.ql-editor{
  min-height:100px;
}
.ql-editor.ql-blank::before{
  font-style: normal;
  font-size: 14px;
}

